<template>
    <v-snackbar
      v-model="value"
      :color="type"
      :multi-line="multiLine"
      right
      light
      :timeout="-1"
    >
      <v-layout
        v-for="(sl, slIndex) in snackList"
        :key="slIndex"
        class="pa-2"
        @click="snackList.splice(slIndex, 1), cc(slIndex)"
      >
        <!-- @mouseover="isMouseOver = true"
        @mouseleave="isMouseOver = false" -->
        <v-icon class="pr-3" dark large>{{ sl.type }}</v-icon>
        <v-layout column>
          <div>
            <strong class="white--text">{{ sl.title }}</strong>
          </div>
          <div class="white--text">{{ sl.text }}</div>
        </v-layout>
        <v-progress-circular
          color="white"
          :rotate="-90"
          :size="35"
          :width="7"
          :value="sl.percentProgress"
        />
        <!-- <v-icon dark @click="$emit('input', false)">mdi-close-circle</v-icon> -->
      </v-layout>
    </v-snackbar>
</template>

<script>
export default {
  name: 'SnackBar',
  components: {
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'info',
      validator: function (value) {
        return ['info', 'success', 'warning', 'error'].indexOf(value) !== -1
      }
    },
    multiLine: {
      type: Boolean,
      default: false,
      required: false
    },
    timeout: {
      type: Number,
      require: false,
      default: 5000
    },
    title: {
      type: String,
      default: '',
      require: true
    },
    text: {
      type: String,
      default: '',
      require: true
    }
  },
  data: () => ({
    interval: null,
    rtimeout: null,
    percentProgress: 0,
    isMouseOver: false,
    snackList: []
  }),
  computed: {
    typeIcon: function () {
      let icon = ''
      if (this.type === 'info') {
        icon = 'mdi-information'
      } else if (this.type === 'success') {
        icon = 'mdi-check-circle'
      } else if (this.type === 'warning') {
        icon = 'mdi-alert'
      } else if (this.type === 'error') {
        icon = 'mdi-alert-circle'
      }
      return icon
    },
    test: function () {
      return this.snackList
    }
  },
  methods: {
    cc: function (index) {
      clearInterval(this.snackList[index])
    },
    startInterval: function (index, timeout) {
      let tout = timeout
      let interval = setInterval(() => {
        tout -= timeout / 100
        this.snackList[index].percentProgress = Math.floor(((this.timeout - tout) / this.timeout) * 100)
        if (tout < 0) {
          clearInterval(interval)
          this.snackList.shift()
          if (this.snackList.length === 0) {
            this.$emit('input', false)
          }
        }
      }, timeout / 100)
      // setTimeout(() => {
      //   this.snackList.shift()
      //   // if (this.snackList.length === 0) {
      //   //   this.$emit('input', false)
      //   // }
      // }, timeout)
    },
    // setTimeout: function () {
    //   this.percentProgress = 0
    //   if (this.value) {
    //     this.rtimeout = setTimeout(() => {
    //       clearInterval(this.interval)
    //       // this.$emit('input', false)
    //     }, this.timeout)
    //   }
    // },
    // setInterval: function () {
    //   this.percentProgress = 0
    //   if (this.value) {
    //     let tout = this.timeout
    //     this.interval = setInterval(() => {
    //       this.percentProgress = Math.floor(((this.timeout - tout) / this.timeout) * 100)
    //       tout -= this.timeout / 100
    //     }, this.timeout / 100)
    //   }
    // },
    // setIntervalz: function (timeout) {
    //   let tout = timeout
    //   let interval = setInterval(() => {
    //     tout -= timeout / 100
    //     console.log(Math.floor(((timeout - tout) / timeout) * 100))
    //     return Math.floor(((timeout - tout) / timeout) * 100)
    //   }, timeout / 100)
    //   setTimeout(() => {
    //     clearInterval(interval)
    //     this.snackList.shift()
    //     // if (this.snackList.length === 0) {
    //     //   this.$emit('input', false)
    //     // }
    //   }, timeout)
    // },
    notifyList: function () {
      this.snackList.push({
        type: this.typeIcon,
        title: this.title,
        text: this.text,
        timeout: this.timeout,
        percentProgress: 0
      })
      // this.startInterval(this.snackList.length - 1, this.timeout)
    }
  },
  watch: {
    isMouseOver: function (val) {
      if (val) {
        clearInterval(this.interval)
        clearTimeout(this.rtimeout)
      } else {
        this.setInterval()
        this.setTimeout()
      }
    }
    // title: function (val) {
    //   this.snackList.push({
    //     type: this.typeIcon,
    //     title: this.title,
    //     text: this.text,
    //     timeout: this.timeout,
    //     percentProgress: 0
    //   })
    // }
  },
  created () {
    // this.setInterval()
    // this.setTimeout()
  }
}
</script>
